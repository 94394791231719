




.resume-section {
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
    & h2 {
        text-align: center;
    }
    max-width: 80%;
    width: 100%;
}

/* src/App.css */


/* src/App.css */

.timeline-date {
    width: 80px; /* Adjust as needed */
    text-align: right;
    font-size: 0.9em;
    color: var(--grey-text);
    margin-right: 20px;
    position: relative;
}

.timeline-date::after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px; /* Adjust based on your margin */
    width: 2px;
    height: 100%;
    background-color: #ccc;
}

.timeline-content {
    padding: 10px 0;
}
.timeline-content>ul{
    list-style: unset;
}
.resume-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    position: relative;
}

.resume-item:last-child .timeline-date::after {
    height: 50%;
}

.resume-item h3 {
    margin: 0;
    font-size: 1.2em;
}

.resume-item h4 {
    margin: 5px 0;
    color: var(--grey-text);
}

.resume-item p {
    margin: 5px 0;
    font-size: 0.9em;
    color: var(--grey-text);
}

.two-cols {
    display: flex;
    flex-direction: row;

}