.mt-1 {
    margin-top: 1rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.add-transition {
    transition: all 0.5s ease
}
