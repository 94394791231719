:root{
    --surface: #c0c0c0;
    --button-highlight: #ffffff;
    --button-face: #dfdfdf;
    --button-shadow: #808080;
    --window-frame: #0a0a0a;
    --dialog-blue: #000080;
    --dialog-blue-light: #1084d0;
    --dialog-gray: #808080;
    --dialog-gray-light: #b5b5b5;
    --link-blue: #0000ff;
    --window-text-color: #000 ;

    --border-width: 1px;
    --border-raised-outer: inset -1px -1px var(--window-frame),
    inset 1px 1px var(--button-highlight);
    --border-raised-inner: inset -2px -2px var(--button-shadow),
    inset 2px 2px var(--button-face);
    --border-sunken-outer: inset -1px -1px var(--button-highlight),
    inset 1px 1px var(--window-frame);
    --border-sunken-inner: inset -2px -2px var(--button-face),
    inset 2px 2px var(--button-shadow);
    --default-button-border-raised-outer: inset -2px -2px var(--window-frame), inset 1px 1px var(--window-frame);
    --default-button-border-raised-inner: inset 2px 2px var(--button-highlight), inset -3px -3px var(--button-shadow), inset 3px 3px var(--button-face);
    --default-button-border-sunken-outer: inset 2px 2px var(--window-frame), inset -1px -1px var(--window-frame);
    --default-button-border-sunken-inner: inset -2px -2px var(--button-highlight), inset 3px 3px var(--button-shadow), inset -3px -3px var(--button-face);
    /* Window borders flip button-face and button-highlight */
    --border-window-outer: inset -1px -1px var(--window-frame),
    inset 1px 1px var(--button-face);
    --border-window-inner: inset -2px -2px var(--button-shadow),
    inset 2px 2px var(--button-highlight);
}




.draggable-window {
    position: absolute;
    width: fit-content;
    height: fit-content;
    border: 2px solid #000;
    background-color: #c0c0c0;
    font-family: 'Arial', sans-serif;
    z-index: 5;
    user-select: none; /* Prevent text selection */
    box-shadow: var(--border-window-outer), var(--border-window-inner);
    background: var(--surface);
    padding: 3px;
}

.window-header {
    font-family: "Big Pixel Light Demo",monospace;
    display: flex;
    background-color: #000080;
    color: white;
    cursor: move;
    justify-content: space-between;
    padding: 3px 2px 3px 3px;


}

.window-content,.window {
    padding: 10px;

}

.cross {
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    width: 30px;
    height: 30px;
    vertical-align: center;
    align-self: end;
    background-color: #bebebe;
    border-radius: 0;
    color: var(--black);
    box-shadow: 2px 2px 0 rgb(0, 0, 0), 1px 1px 0 rgb(219, 235, 239) inset;


}

.title-bar-text {
    font-weight: bold;
    color: white;
    letter-spacing: 0;
    margin-right: 24px;
}


.title-bar-controls button {
    padding: 0;
    display: block;
    min-width: 16px;
    min-height: 14px;
}

.title-bar-controls button:active {
    padding: 0;
}

.title-bar-controls button:focus {
    outline: none;
}

.title-bar-text {
    font-weight: bold;
    color: white;
    letter-spacing: 0;
    margin-right: 24px;
}

.title-bar-controls {
    display: flex;
}

.window > p, .window>h1,.window>h2,.window>h3,.window>h3,.window>h6,.window>div{
    color: var(--window-text-color);
}
