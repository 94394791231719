/* Container for the whole card */
.window98-card {
    width: 70%;
    border: 2px solid #000;
    border-top-color: #fff;
    border-left-color: #fff;
    border-radius: 4px;
    box-shadow: 2px 2px 0 #333;
    font-family: 'MS Sans Serif', sans-serif;
    background-color: #c0c0c0;
    margin: 20px auto;
}

/* Title bar styling */
.window98-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000080;
    color: #fff;
    padding: 4px;
    font-size: 14px;
}

/* Title text */
.window98-title {
    margin-left: 8px;
}

/* Window buttons styling */
.window98-buttons {
    display: flex;
    margin-right: 8px;
}

.window98-button {
    width: 1.5rem;
    border-radius: 0;
    font-weight: bold;
    color: #000;
    background-color: #c0c0c0;
    padding: 2px 6px;
    margin-left: 2px;
    border: 1px solid #000;
    box-shadow: 1px 1px 0px #fff inset;
    cursor: pointer;
}

.window98-button:hover {
    background-color: #808080;
}

/* Content area styling */
.window98-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 16px;
    background-color: #c0c0c0;
    border-top: 2px solid #808080;
    border-left: 2px solid #808080;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
}
.cover-img{
    max-width: 300px;
    object-fit: cover;
}
.tech-stack {
    display: flex;
    align-items: center;

    flex-direction: row;
    gap: 5px;
}


@media (max-width: 991px) {
    .cover-img{
        max-width: 300px;
        height: 100%;
    }
    .window98-card{
        width: 95%;

    }
    .window98-content{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    }

.blink {
    animation: blink 0.75s infinite ease-in;

}
@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
}