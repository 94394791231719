:root {
    --pixelbtn-color-base: rgb(54, 185, 175);
    --pixelbtn-color-bevel: #20605b;
    --pixelbtn-color-shadow: rgb(0, 0, 0);

    --pixelbtn-color-base-hover: rgb(75, 234, 224);
    --pixelbtn-color-bevel-hover: rgb(49, 140, 134);
    --pixelbtn-color-shadow-hover: rgb(0, 0, 0);
    --pixelbtn-color-txt: rgb(0, 46, 52);
}

.PixellButton {


    width: fit-content;
    min-width: 150px;
    height: fit-content;
    min-height: 50px;
    bottom: 30px;
    background-color: var(--pixelbtn-color-base);
    padding: 15px;
    color: var(--pixelbtn-color-txt);
    font-family: PressStart,monospace;
    margin-left: auto;
    margin-right: auto;
    border-color: var(--pixelbtn-color-bevel);
    border-right: 4px solid var(--pixelbtn-color-bevel);
    border-bottom: 6px solid var(--pixelbtn-color-bevel);
    box-shadow: -3px 0 0 0 var(--pixelbtn-color-shadow),
    3px 0 0 0 var(--pixelbtn-color-shadow),
    0 -3px 0 0 var(--pixelbtn-color-shadow),
    0 3px 0 0 var(--pixelbtn-color-shadow);
}

.PixellButton:hover {

    background-color: var(--pixelbtn-color-base-hover);
    border-color: var(--pixelbtn-color-bevel-hover);

}

.PixellButton:active {
    background-color: rgb(54, 184, 175);
    transform: translateY(3px);
    border-right: 4px solid #2c7671;
    border-bottom: 5px solid #194643;
}