.header {
    background-color: var(--color-navbar);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}
.navbar {
    display: flex;
    height: var(--size-navbar);
    justify-content: space-between;
    margin: 0 auto;
    width: inherit;
    padding: 0 1rem;
}
.nav-list {
    font-size: 1.5rem;
    list-style: none;
    justify-self: center;
    margin:auto;
    gap: 1rem;
    display: flex;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    font-family: 'Orbit', sans-serif;
}

.nav-list-items {
    margin: 0 20px;
    align-self: center;
    width: fit-content;
    padding: 0.5rem;
    cursor: pointer;
    font-family: "led",monospace;

}
.nav-list-items>a:hover {
    color: var(--color-accent)

}
.nav-list-items>a{
    color: var(--color-primary);

}

.hamburger {
    color: var(--color-primary);
    background: none;
    border: none;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.38);
    display: none;
    opacity: 1;
    transition: opacity 0.2s ease;
    font-size: 2rem;
    cursor: pointer;
    justify-content: center;
    margin: auto;
    rotate: 90deg;
}
.hamburger:hover{
    background: none;
    border: none;
    text-shadow: 0 0 2px var(--color-accent);
    color: var(--color-accent);
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-7px);
    }
}
.logo {
    width: 100%;
    height: 2.5rem;
    transition: all 3s ease;
    cursor: pointer;
    animation: float 3s ease-in-out infinite;


}
.logo.inverted{

}
.logo:hover {
    animation-play-state: paused;
}
@media (max-width: 768px) {
    header {
        padding: 10px 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
    }

    .nav-list {
        flex-direction: column;
        background-color: var(--color-background);
        top: 40px;
        left: 0;
        width: 100%;
        transform: translate(0,-400px);
        transition: all 0.2s ease-in;
    }

    .nav-list-items {
        padding: 0.5em;

    }

    .proj-name {
        font-size: 5vw;
    }



    /* Show the mobile menu button in mobile mode */
    .hamburger {
        display: block;
        text-align: right;

    }
    .hamburger span {
        display: block;
        width: 100%;
        height: 4px;
        background-color: #333;
        transition: 0.3s;
    }
    .nav-list.active {
        transform: translate(0,0);

    }


    /* Add fade-out animation for the mobile menu button */
    .hamburger.open {
        opacity: 0;

    }

}