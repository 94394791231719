
.console-command-container{
    display: flex;
    min-height: 90vh;
    flex-direction: column;


}
#commandHistory>div>a{
    text-decoration: underline;
    color: #db3434;
}

/*noinspection CssUnresolvedCustomProperty*/
#commandHistory {
    padding-left: 10px;
    padding-top: 20px;
    width: 100%;
    max-height: 85vh;
    background-color: var(--background-color);
    position: relative;
    overflow-y: auto;
    font-family: cmd,monospace;
    letter-spacing: 0.05rem;


}
#commandHistory> section>p{
    font-size:max(12px,1vw) ;
    height: auto;
}
#commandHistory> section{
    font-family: cmd,monospace;

    font-size: 1rem;
    height: auto;
}

/*noinspection CssUnresolvedCustomProperty*/
#consoleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--background-color);;
}
#consoleLabel {
    font-family: cmd,monospace;
    white-space: nowrap;
    padding-right: 2px;
}

/*noinspection CssUnresolvedCustomProperty*/
#console {
    width: 100%;
    font-size:max(1rem,1vw) ;
    caret-shape: block;
    font-family: cmd,monospace;
    letter-spacing: 0.05rem;
    background-color: var(--background-color);
    margin: 0;
    resize: none;
    outline: none;
    border: none;
    box-shadow: none;

}
textarea{
    caret-shape: block;
    color: var(--color-text);

    letter-spacing: 0.05rem;

}
#console:focus {
    outline: none;

}

@media (max-width: 768px) {

    #consoleContainer, #commandHistory, #consoleContainer > input {
        font-size: 14px;
    }

    #commandHistory > section > h2 {
        font-size: 30px;
        height: auto;
    }
}

.command-history {
 padding: 0;
    font-family: cmd,monospace;
    letter-spacing: 0.1rem;
}