input, textarea, select, button {
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
}

input:focus, textarea:focus, select:focus {
    border-color: #3498db;
}

form button {
    background-color: #3498db;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
}

form button:hover {
    background-color: #2980b9;
}

form button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
