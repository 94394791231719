:root, [data-theme="dark"] {
    --color-primary: #3dffd2;
    --color-secondary: #00ded7;
    --color-background: #18191a;

    --color-text: #ffffff;
    --color-accent: #48CFCB;

    --card-background: #273041;
    --bg-art-color: #033103;
    --border-color: #000000;
    --highlight-color: #27ad5f;

    --inactive-tab-shadow: rgba(0, 0, 0, 0.72);
    --active-tab-shadow: rgba(255, 255, 255, 0.6);
    --black: #141415;

    --ascii-title-size: 1.5rem;

    --color-navbar: #387478;

    --grey-text: #c7c7c7;
}

[data-theme="light"] {
    --color-primary: #25ffd2;
    --color-secondary: #00425e;
    --color-background: #e9f0f6;
    --color-text: #000000;
    --color-accent: #19a6b9;

    --card-background: rgba(103, 126, 173, 0.51);
    --bg-art-color: #e2e2f3;

    --border-color: #000000;
    --highlight-color: #27ad5f;
    --grey-text: #4b4b4b;


}

:root {
    --size-navbar: 4rem;
}

body {
    margin: var(--size-navbar) 0 0;
    font-family:  BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

blockquote {
    padding: 1rem;
    margin: 1rem 0;
    border-left: 4px solid #3498db;
    color: #555;
}

code {
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    font-family: cmd,monospace;
}

pre {
    color: #ecf0f1;
    padding: 1rem;
    overflow: auto;
    line-height: 1rem;
    font-family: monospace;
    white-space: pre;

}

.page-title {
    align-self: center;
    text-align: center;
}

/* CSS Reset */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
#root{
    height: fit-content;
}
html, body{
    height: 100%;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img, video {
    max-width: 100%;
    height: auto;
    display: block;
}

a {
    text-decoration: none;
    color: inherit;
}

ul, ol {
    list-style: none;
}

/* Typography */
h1, h2, h3, h4 {
    color: var(--color-text);
    margin-bottom: 0.75rem;
}

h1 {
    font-size: 2.5rem;
    font-family: titles, monospace;
}

h2 {
    font-size: 2rem;
    font-family: subtitle, monospace;

}

h3 {
    font-size: 1.7rem;
    font-family: subtitle, monospace;

}

h4 {
    font-size: 1.6rem;

}


p {
    font-size: 1.2rem;
    color: var(--color-text);
    font-family: texts, monospace;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.37);
    line-height: 2rem;

}

a {
    transition: color 0.2s ease-in-out;
}

a:hover {
}

a:visited {
    text-decoration: none;
}

